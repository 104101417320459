<template>
<div class="container">
    <div class="row">
        <FAQx />
        <FAQads />
        <FAQdispute />
        <FloatingButton 
            icon="fas fa-arrow-circle-left" 
            @click="goBack"
            v-if="store.state.isLogged!=false" 
        />
    </div>
</div>
</template>

<script>
import FAQx from '@/components/FAQ/FAQx';
import FAQads from '@/components/FAQ/FAQads';
import FAQdispute from '@/components/FAQ/FAQdispute';
import { onMounted } from '@vue/runtime-core';
import useBoilerplate from '@/composables/useBoilerplate';
import FloatingButton from '@/components/UI/Buttons/FloatingButton'

export default {
    components: { FAQx, FAQads, FAQdispute, FloatingButton },
    setup(){

        const { store, router } = useBoilerplate(false);
        
        const goBack = () => router.go(-1);
        
        onMounted(() => {
            M.AutoInit();
        })
        
        return { store, goBack }
    }
}
</script>