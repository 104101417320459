<template>
    <div class="col s12 center-align orange margin">
        <h5 class = white-text>Preguntas Frecuentes</h5>
    </div>
    <div class="col s12 left-align orange-text">
        <h6>Funcionamiento del Exchange</h6>
    </div>
    <div class="col s12">
        <ul class = "collapsible">
            <li>
                <div class = "collapsible-header grey darken-2 white-text">
                    <h6>Cómo funciona el proceso de compraventa segura?</h6>
                </div>
                <div class = "collapsible-body">
                    <p>
                    - Para iniciar, <span class = 'orange-text'>seleccione un anuncio</span> y haga click en el boton "Vende Bitcoin" o "Compra Bitcoin" visible en los detalles de cada anuncio<br><br>
                    - <span class = 'orange-text'>El vendedor enviará los Bitcoin </span>a una dirección Bitcoin multiSig 2/3 (comprador, vendedor, QBita) que actúa como escrow.<br><br>
                    - En cuanto la transacción esté confirmada en la Blockchain de Bitcoin <span class ='orange-text'>el comprador envía al vendedor el monto FIAT (CUP) establecido</span>, utilizando el método de pago que se específica  en el anuncio<br><br>
                    - <span class = 'orange-text'>En cuanto el vendedor reciba el pago, confirmará la recepción de los fondos </span> y los Bitcoin pasan de forma automática de la cuenta escrow al monedero comprador<br><br>
                    </p>
                </div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-1 white-text">
                    <h6 id = 'QnoMail'>Cuáles son los costos de la compraventa segura?</h6>
                </div>
                <div class = "collapsible-body">
                    <p id = 'AnoMail'>
                    Los costos son los siguientes:<br><br>
                    - <span class = 'orange-text'>Fee de la red Bitcoin:</span> el vendedor paga para el envío de los Bitcoin a la dirección escrow multiSig. La fee depende de varios factores: modenero utililzado, congestión de la red Bitcoin, tiempos de confirmación deseado. El comprador paga para el envío de los Bitcoin desde el escrow multiSig a la dirección de su monedero. La fee es calculada dinamicamente al momento de la entrega de los fondos para que la transacción sea confirmada dentro de 6 bloques.<br><br>
                    - <span class = 'orange-text'>Escrow Fee para QBita:</span> 1% para todos con 2 simples reglas. Primero: la fee minima es de 1 $. Segundo: si el monto de la compraventa es mayor de 1000 $, se aplica la fee del 1% para los primeros 1000 $ y la fee de 0.5% para la parte del total que supera los 1000 $. Esta fee la paga el vendedor a QBita en automático cuando se finalize la compraventa<br><br>
                    <b class="red-text">IMPORTANTE:</b>Si el vendedor es un <b>PROVEDOR de LIQUIDEZ</b> (<i class="fas fa-star yellow-text"></i>) las comisiones de compraventa de QBita son pagadas por el comprador, al cual se le va descontar 2.50$ + el 0.85% del monto en BTC que es objeto de la compraventa<br><br>
                    - <span class = 'orange-text'>RBF (Servicio Opcional de Acceleracion de Transacciónes):</span> el usuario que va a recibir Bitcoin puede activar este servicio opcional, que le da la posibilidad de accelerar la transacción de entrega de los Bitcoin por medio de RBF en caso de necesidad. Para este servicio QBita cobra 160 satoshi por cada sat/byte de fee de mineria aplicado por el usuario. Por ejemplo, si Fulano pone una fee de mineria de 30 sat/byte, Qbita cobra 4800 satoshi (160 * 30) para el servicio RBF. <br><br>
                    </p>
                </div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-2 white-text"><h6 id = 'QhowNotConfirmed'>Que es RBF?</h6></div>
                <div class = "collapsible-body">
                    <p id = 'AhowNotConfirmed'>
                    RBF es una funcionalidad del protocolo Bitcoin che permite remplazar una transacción estancada en el mempool (producto a la aplicación de una fee de mineria insuficiente) con una nueva transacción con fee de mineria mas alta, accelerando asi los tiempos de confirmación en la blockchain. Qbita soporta RBF como funcionalidad Premium (mas info en la seccion sobre los costos de los servicios de QBita). 
                    </p>
                </div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-1 white-text"><h6 id = 'QhowNotConfirmed'>Se necesita hacer el KYC (know your customer) para utilizar el exchange?</h6></div>
                <div class = "collapsible-body"><p id = 'AhowNotConfirmed'>No, el KYC no es necesario. QBita es totalmente descentralizado y no tiene custodia ni las claves ni los fondos de sus usuarios. Por lo tanto hacer el KYC no tiene sentido.</p></div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-2 white-text" ><h6>Cómo puedo averiguar la identidad del comprador/vendedor con el cual voy a hacer negocio?</h6></div>
                <div class = "collapsible-body">
                    <p>
                    Es razonable que un usuario desee conocer con quien va a hacer negocio. Por lo tanto es totalmente normal pedir a la contraparte que envíe por chat una foto de su carnet. Es incluso mejor intercambiar también fotos donde se vea la persona con su carnet.
                    </p>
                </div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-1 white-text"><h6 id = 'QhowNotConfirmed'>Cómo puedo saber si un cierto vendedor/comprador es de confianza?</h6></div>
                <div class = "collapsible-body"><p id = 'AhowNotConfirmed'>QBita tiene un sistema de reputación que permite a los usuarios de evaluar la seriedad de la controparte cada vez que se concluye una transacción. Los parametros de reputación de cada usuario son públicos, para que todos puedan tener una idea de la conducta de cada usuario y decidir si le conviene más hacer negocio con uno o con otro.</p></div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-2 white-text"><h6 id = 'QhowNotConfirmed'>QBita tiene acceso a fotos y mensajes enviados por chat?</h6></div>
                <div class = "collapsible-body"><p id = 'AhowNotConfirmed'>Los mensajes y las fotos intercambiadas son guardadas en la base de datos de QBita y son criptados con AES-128. QBita tiene acceso a los mensajes enviados por chat solo si el comprador o el vendedor abren una disputa.</p></div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-1 white-text"><h6 id = 'QhowNotConfirmed'>Recibí una foto por chat y desapareció. Qué pasa?</h6></div>
                <div class = "collapsible-body"><p id = 'AhowNotConfirmed'>QBita borra las fotos después de 24 horas, hayan sido visualizadas o no. El usario tiene la responsabilidad de guardar en su propio dispositivo las fotos recibidas más importantes (por ejemplo evidencias de un pago recibido)</p></div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-2 white-text"><h6 id = 'QhowLostPhone'>Que es una dirección Bitcoin multiSig?</h6></div>
                <div class = "collapsible-body"><p id = 'AhowLostPhone'>Es una dirección pública Bitcoin cuyos fondos son controlados conjuntamente por varios actores. En el caso de QBita, una nueva dirección multiSig es creada dinamicamente cada vez que se inicia una compraventa segura. En QBita las partes que controlan los fondos son 3: el comprador, el vendedor y QBita. Para sacar los fondos, la transacción tiene que ser firmada con las claves de 2 de los 3 actores que controlan la dirección multiSig. De esta manera nadie puede estafar a nadie.</p></div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-1 white-text"><h6 id = 'QhowLostPhone'>Por qué utilizar una dirección multiSig como escrow?</h6></div>
                <div class = "collapsible-body"><p id = 'AhowLostPhone'>Si un comprador y un vendedor deciden transar sin un escrow, el que envíe primero el dinero o los Bitcoin corre el riesgo de ser estafado por la otra parte, que puede quedarse con el dinero/Bitcoin sin enviar nada al otro. Para resolver este problema se utiliza un escrow, que es un intermediario independiente que recibe los Bitcoin del vendedor y los envía al comprador solo después que este haya confirmado haber recibido el dinero FIAT (CUP, Dolares, Saldo telefónico...). Sin embargo, confiar en terceras partes es totalmente contrario a la filosofia del ecosistema Bitcoin y expone igualmente las partes al riesgo de estafa (...del escrow). Por lo tanto, QBita utiliza como escrow una dirección multiSig (2/3) dinámica, controlada conjuntamente por comprador, vendedor y QBita. Ninguna de las 3 partes puede mover los fondos sin el consenso de al menos una de las dos otras partes.</p></div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-2 white-text"><h6 id = 'QhowLostSeed'>No quiero usar los servicios de compraventa segura. Es posible?</h6></div>
                <div class = "collapsible-body"> <p id = 'AhowLostSeed'>Si es posible, si ambas partes aceptan los riesgos de transar con desconocidos sin un escrow en el medio.</p> </div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-1 white-text"><h6 id = 'QhowForgotPW'>El comprador dice que me envió el dinero, pero no recibí nada. Qué hago?</h6></div>
                <div class = "collapsible-body"><p id = 'AhowForgotPW'>Haga click en el boton "Disputa" para que QBita averigue la situación y resuelva el problema. No es posible abrir una disputa después que se haya confirmado la recepción del dinero y los Bitcoin hayan sido transferidos al comprador.</p></div>
            </li>
        </ul>
    </div>
</template>

<style scoped>
    .collapsible-body{
        background-color: white;
    }
</style>