<template>
    <div class="col s12 left-align orange-text">
        <h6>Publicacion de Anuncios</h6>
    </div>
    <div class="col s12">
        <ul class = "collapsible">
            <li>
                <div class = "collapsible-header grey darken-2 white-text"><h6 id = 'QhowNew'>Tipo de operación</h6></div>
                <div class = "collapsible-body"><p id = 'AhowNew'>El usuario debe especificar en su anuncio si desea vender o comprar Bitcoin.</p></div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-1 white-text"><h6 id = 'QhowNew'>Alcanze geográfico del anuncio</h6></div>
                <div class = "collapsible-body"><p id = 'AhowNew'>Es posible especificar la provincia donde se desea realizar la venta y también es posible especificar el parametro "Cuba entera", si desea vender en todo el territorio Nacional. En ambos casos, el anuncio será visible para todos los usuarios.</p></div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-2 white-text"><h6 id = 'QhowNew'>Divisa de Pago</h6></div>
                <div class = "collapsible-body"><p id = 'AhowNew'>Es necesario especificar la divisa que se utilizara para comprar o vender los Bitcoin.</p></div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-1 white-text"><h6 id = 'QnoMail'>Cómo especificar el monto de Bitcoin que quiero comprar/vender?</h6></div>
                <div class = "collapsible-body"><p id = 'AnoMail'>El monto representa la cantidad de Bitcoin (espresada por su contravalor en Dolares Americanos) que Usted quiere comprar o vender. Por supuesto es posible también establecer un alcanze terrirorial que corresponde a la totalidad del territorio nacional (opción "Cuba Entera")</p></div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-2 white-text"><h6 id = 'QhowFogotPW'>Cuál es la tasa de cambio BTC/USD?</h6></div>
                <div class = "collapsible-body"><p id = 'AhowFogotPW'>QBita calcula en tiempo real el cambio BTC/USD de forma automática, según la tasa de cambio de blockchain.com (la misma utilizada en el monedero). La cantidad de Bitcoin objeto de la compraventa fluctúa hasta el momento en que las partes se acuerden para empezar la transacción de intercambio. Por ejemplo, si el cambio BTC/USD está en 10.000 USD, 1000 USD corresponden a 0.1 BTC. Si el cambio BTC/USD esta en 5.000 USD, los mismos 1000 USD corresponden a 0.2 BTC.</p></div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-1 white-text"><h6 id = 'QhowForgotPW'>Divisa FIAT utilizadas para comprar/vender los Bitcoin</h6></div>
                <div class = "collapsible-body"><p id = 'AhowForgotPW'>Los anuncios muestran los precios en FIAT (CUP) de los Bitcoin propuestos en el anuncio. Los precios son dinámicos y fluctuan hasta el momento en el cual se abre una compraventa. Para calcular el precio en FIAT se suma el valor de los Bitcoin expresado en Dolares Americanos, más el margen del vendedor. Ipotizando una compraventa de Bitcoin por un valor de 100 usd y un margen del vendedor del 18%, el precio FIAT que el comprador tiene que pagar al vendedor será de 2832 CUP.</p></div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-2 white-text"><h6 id = 'QhowLostPhone'>Métodos de pago</h6></div>
                <div class = "collapsible-body"><p id = 'AhowLostPhone'>Seleccione al menos un método de pago. El que conteste a un anuncio acepta transar utilizando el método de pago especificado en el anuncio</p></div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-1 white-text"><h6 id = 'QhowNotConfirmed'>Selección del margen</h6></div>
                <div class = "collapsible-body"><p id = 'AhowNotConfirmed'>El margen indica, en percentage (%), el extra por encima del precio del cambio oficial que el comprador está dispuesto a pagar al vendedor para finalizar la compraventa.</p></div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-2 white-text"><h6 id = 'QhowAskMnemonic'>Qué son las condiciones de compraventa?</h6></div>
                <div class = "collapsible-body"><p id = 'AhowAskMnemonic'>En este campo el autor del anuncio (vendedor o comprador), tiene la posibilidad de especificar algunas condiciones extra que regularán la interacción con la otra parte durante la compraventa. Por ejemplo, un vendedor puede especificar que solo quiere chatear con compradores que estén dispuestos a transar de inmediato. Otro ejemplo sería un vendedor que pide ser contactado solo por vendedores que hayan alcanzado ciertos niveles de reputación (estrellas) en la plataforma</p></div>
            </li>
        </ul>
    </div>
</template>

<style scoped>
    .collapsible-body{
        background-color: white;
    }
</style>