<template>
    <div class="col s12 left-align orange-text">
        <h6>Gestion de las Disputas</h6>
    </div>
    <div class="col s12">
        <ul class = "collapsible">
            <li>
                <div class = "collapsible-header grey darken-2 white-text"><h6>En una disputa cómo puedo demonstrar que realmente envié el dinero al vendedor?</h6></div>
                <div class = "collapsible-body"><p>Las capturas de pantalla y las fotos de recibos de pago son un buen método . Pero grabar el envío de dinero por medio de una aplicación movil es incluso mejor, mostrando fecha, detalles del destinatario, monto y confirmación.</p></div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-1 white-text"><h6>El vendedor envió  pruebas del envío pero yo no he recibido el dinero. Cómo puedo comprobarlo?</h6></div>
                <div class = "collapsible-body"><p>QBita le va a pedir evidencias específicas en dependencia del método de pago utilizado</p></div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-2 white-text"><h6>El comprador me envió solo una parte del dinero. Cómo hago?</h6></div>
                <div class = "collapsible-body"><p>Puede abrir una disputa. QBita va a intentar primero convencer el comprador a enviarle el monto que falta. Si no es posible resolver por esa vía, la disputa será resuelta en su favor.</p></div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-1 white-text"><h6>Cuánto dura el proceso de disputa?</h6></div>
                <div class = "collapsible-body"><p>Depende. El objetivo no es resolver la disputa rapidamente, sino resolverla correctamente.</p></div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-2 white-text"><h6>Cuanto tiempo tengo para contestar a las preguntas de QBita?</h6></div>
                <div class = "collapsible-body"><p>Depende. Cada vez que QBita solicite informaciones para la resolución de una disputa, le va a especificar el tiempo dentro del cual Usted debe contestar. El tiempo de respuesta es siempre de varias horas.</p></div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-1 white-text"><h6>Que pasa si no contesto a tiempo o no envió las evidencias solicitadas?</h6></div>
                <div class = "collapsible-body"><p>QBita en este caso va a resolver la disputa en favor de la contraparte.</p></div>
            </li>
            <li>
                <div class = "collapsible-header grey darken-2 white-text"><h6>Los chat con la contraparte en Whatsapp/Messenger/Telegram/IMO son evidencias válidas?</h6></div>
                <div class = "collapsible-body"><p>No. Solo los chats en la aplicación QBita son evidencias válidas de los acuerdos entre las partes.</p></div>
            </li>
        </ul>
    </div>
</template>

<style scoped>
    .collapsible-body{
        background-color: white;
    }
</style>